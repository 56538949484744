@import "~typeface-roboto/index.css";
@import "~@osjs/gui/dist/main.css";
@import "~@osjs/dialogs/dist/main.css";
@import "~@osjs/panels/dist/main.css";
@import url("https://fonts.googleapis.com/css?family=Lato:400,400i,700,900");
@import "~@fortawesome/fontawesome-pro/css/all.min.css";


html {
  font-family: "Lato";
}
.osjs-window-header {
  height: 3em;
}
.osjs-window-icon > div {
  height: 3em;
  width: 3em;
  margin-left: 6px;
}
.osjs-notifications {
  top: 45px;
}

.osjs-panel-item .logo-here img {
  width: 150px;
}
